.view-warpper {
  max-width: 16rem;
  margin: 0 auto;
  padding: 0.533333rem;
}

.view-warpper .logo {
  display: flex;
  align-items: center;
  font-size: 0.48rem;
  margin-bottom: 0.4rem;
}

.view-warpper .logo span {
  margin-left: 0.213333rem;
}

.view-warpper .title {
  padding: 0.8rem;
  background: url("http://aimianshiguan.oss-cn-beijing.aliyuncs.com/resume/89536051179675339.png") no-repeat center;
  text-align: center;
  color: #ffffff;
  border-radius: 0.213333rem;
  background-size: cover;
}

.view-warpper .title .h1 {
  font-size: 0.533333rem;
  font-weight: bold;
}

.view-warpper .title .h2 {
  margin-top: 0.4rem;
  font-size: 0.426667rem;
}

.view-warpper .itemTime-list {
  width: 100%;
  background: linear-gradient(180deg, rgba(255,255,255,0.9) 0%, #fff 100%);
  box-shadow: 0 0.106667rem 0.213333rem 0 rgba(0,0,0,0.08);
  border-radius: 0.213333rem;
  margin-top: 0.213333rem;
}

.view-warpper .itemTime-list .itemTime:last-child {
  border: 0;
}

.view-warpper .itemTime-list .itemTime {
  padding: 0.133333rem 0;
  text-align: center;
  border-bottom: 0.026667rem solid #E5E5E5;
}

.view-warpper .itemTime-list .itemTime .label {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.64rem;
  font-style: normal;
}

.view-warpper .itemTime-list .itemTime .value {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.586667rem;
  font-style: normal;
}

.view-warpper .content {
  margin: 0.4rem 0;
}

.view-warpper .content .item {
  display: flex;
  border-bottom: 0.026667rem solid #f2f2f2;
  justify-content: space-between;
  align-items: center;
  font-size: 0.32rem;
}

.view-warpper .content .item .label {
  color: #a6a6a6;
}

.view-warpper .content .item .value {
  font-weight: bold;
}

.view-warpper .content .invite-status {
  flex-direction: column;
  align-items: baseline;
  border: 0;
}

.view-warpper .content .invite-status .c-status {
  color: #d9d9d9;
  margin-bottom: 0.213333rem;
}

.view-warpper .content .invite-status .bt-status {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.view-warpper .content .invite-status .bt-status .cs-bt {
  width: 48%;
  border-radius: 0.133333rem;
}

.view-warpper .content .invite-status .invite-label {
  border: 0.026667rem solid #2C9AFF;
  text-align: center;
  width: 100%;
  padding: 0.266667rem 0;
  border-radius: 0.08rem;
}

.view-warpper .content .content-item {
  display: flex;
  font-size: 0.426667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.586667rem;
  margin-bottom: 0.32rem;
}

.view-warpper .content .content-item .content-item_label {
  width: 3.2rem;
  min-width: 3.2rem;
  text-align: left;
}

.view-warpper .content .content-item .content-item_value {
  word-break: break-word;
}

.view-warpper .method .tip {
  font-size: 0.48rem;
  margin-bottom: 0.266667rem;
}

.view-warpper .method .control {
  display: flex;
  padding: 0.4rem;
  background: #F5F7FA;
  align-items: center;
  font-size: 0.426667rem;
  margin-bottom: 0.266667rem;
  border-radius: 0.213333rem;
}

.view-warpper .method .control .control_icon {
  height: 0.64rem;
  width: 0.64rem;
  margin-right: 0.133333rem;
}

.view-warpper .method .control .control_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.view-warpper .method .control .control_item>div {
  display: flex;
}

.view-warpper .method .control .label {
  color: #333;
  font-weight: bold;
}

.view-warpper .method .control .btn {
  display: inline-block;
  cursor: pointer;
  border-radius: 0.106667rem;
  border: 0.026667rem solid #2C9AFF;
  color: #2C9AFF;
  background: #ffffff;
  padding: 0.133333rem 0;
  text-align: center;
  width: 2.666667rem;
}

.view-warpper .method .control .control_item_psw {
  background: rgba(44,154,255,0.1);
  border-radius: 0.106667rem;
  text-align: center;
  width: 100%;
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.view-warpper .method .control .control_item_psw .psw {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.426667rem;
  color: #2C9AFF;
}

.view-warpper .method .mobile_tip {
  display: flex;
  justify-content: center;
}

.view-warpper .method .mobile_tip .btn {
  cursor: pointer;
  color: #5ba6ef;
}

.view-warpper .method .tip_text {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.346667rem;
  color: #666666;
  width: 100%;
  line-height: 1.5;
}

.view-warpper .hotline {
  margin: 0.8rem 0;
  font-size: 0.426667rem;
  color: #8f8f8f;
  text-align: center;
}

.view-warpper .qrcode {
  margin: 0.8rem 0;
  text-align: center;
}

.view-warpper .qrcode div {
  margin-top: 0.213333rem;
}

.view-warpper .button {
  display: flex;
  flex-direction: column;
}

.view-warpper .button .van-button+.van-button {
  margin-top: 0.533333rem;
}